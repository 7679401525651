<template>
  <div class="button-preview__container">
    <div class="button-preview__online-container">
      <div class="button-preview__online-container-online"></div>
    </div>
    <div class="button-preview__minimize-container flex justify-center items-center" :style="step2ButtonColor" v-if="canShowMinimizeIcon">
      <close-minimize-icon :width="9" :height="9" :color="dialog.step2ButtonColor" :stroke="step2IconColor" />
    </div>
    <div class="button-preview__powered-by-container">
      <div
        class="button-preview__powered-by-container-header"
        :class="{
          _small: previewType === 'small' || (isForPreviewScreen && dialog.isAlwaysShowButtonEnabled)
        }"
      >
        {{ dialogSloganTitle }}
      </div>
    </div>
    <template v-if="!isForPreviewScreen">
      <template v-if="showDialogDefaultMedia">
        <div v-if="dialog && !dialog.showVideo" class="button-preview__img-container">
          <img class="button-preview__img" :src="imageURL" :key="mediaKey" />
        </div>
        <div v-else class="button-preview__video-container">
          <video class="button-preview__video" v-if="mediaImg" playsinline autoplay muted loop :key="mediaKey">
            <source v-if="mediaImg.indexOf('.webm') > -1" :src="mediaImg" :type="webm" />
            <source v-if="mediaImg.indexOf('.mp4') > -1" :src="mediaImg" :type="mp4" />
          </video>
        </div>
      </template>
      <template v-else>
        <template v-if="mediaImg">
          <div v-if="mediaImg.indexOf('.webm') > -1 || mediaImg.indexOf('.mp4') > -1" class="button-preview__video-container">
            <video class="button-preview__video" playsinline autoplay muted loop :key="mediaKey">
              <source v-if="mediaImg.indexOf('.webm') > -1" :src="mediaImg" :type="webm" />
              <source v-if="mediaImg.indexOf('.mp4') > -1" :src="mediaImg" :type="mp4" />
            </video>
          </div>
          <div v-else class="button-preview__img-container">
            <img class="button-preview__img" :src="mediaImg" :key="mediaKey" />
          </div>
        </template>
      </template>
    </template>
    <template v-if="isForPreviewScreen">
      <template v-if="dialog && !dialog.isSmallButtonPreviewVisible">
        <template v-if="showDialogDefaultMedia">
          <div v-if="dialog && !dialog.showVideo" class="button-preview__img-container">
            <img class="button-preview__img" :src="imageURL" />
          </div>
          <div v-else class="button-preview__video-container">
            <video v-if="mediaImg" class="button-preview__video" playsinline autoplay muted loop :key="mediaKey">
              <source v-if="mediaImg.indexOf('.webm') > -1" :src="mediaImg" :type="webm" />
              <source v-if="mediaImg.indexOf('.mp4') > -1" :src="mediaImg" :type="mp4" />
            </video>
          </div>
        </template>
        <template v-else>
          <template v-if="mediaImg">
            <div v-if="mediaImg.indexOf('.webm') > -1 || mediaImg.indexOf('.mp4') > -1" class="button-preview__video-container">
              <video class="button-preview__video" playsinline autoplay muted loop :key="mediaKey">
                <source v-if="mediaImg.indexOf('.webm') > -1" :src="mediaImg" :type="webm" />
                <source v-if="mediaImg.indexOf('.mp4') > -1" :src="mediaImg" :type="mp4" />
              </video>
            </div>
            <div v-else class="button-preview__img-container">
              <img class="button-preview__img" :src="mediaImg" :key="mediaKey" />
            </div>
          </template>
        </template>
      </template>
      <template v-else>
        <div class="button-preview__smallest-img-container">
          <div class="button-preview__smallest-img">
            <img :src="smallButtonImage" :key="mediaKey" />
          </div>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import CloseMinimizeIcon from '@/components/icons/CloseMinimizeIcon'
export default {
  components: {
    CloseMinimizeIcon
  },
  props: {
    ownMediaKey: {
      type: String
    },
    canShowMinimizeIcon: {
      type: Boolean,
      default: true
    },
    isForPreviewScreen: {
      type: Boolean,
      default: false
    },
    previewType: {
      type: String,
      default: ''
    },
    uploadType: {
      type: String
    },
    uploadSubtype: {
      type: String
    },
    dialog: {
      type: Object,
      required: true
    },
    imageURL: {
      type: String,
      required: false,
      default: null
    },
    embedVideoURL: {
      type: String,
      required: true
    },
    smallButtonImage: {
      type: String,
      required: false,
      default: null
    }
  },
  mounted() {
    this.videoKey = Math.random().toString(36).substring(2, 15)
  },
  data() {
    return {
      webm: 'video/webm',
      mp4: 'video/mp4',
      defaultSloganTitle: "Let's Connect!",
      videoKey: Math.random().toString(36).substring(2, 15)
    }
  },
  computed: {
    ...mapGetters({
      hasWhiteLabel: 'hasWhiteLabel',
      selectedLanguageForWebConnect: 'widgetTranslation/selectedLanguageForWebConnect',
      selectedWidgetTranslation: 'widgetTranslation/selectedWidgetTranslation'
    }),
    showDialogDefaultMedia() {
      if (this.dialog && this.dialog.showDefaultMedia) {
        return true
      }
      return false
    },
    mediaKey() {
      const mediaKey = this.videoKey
      const showVideo = this.dialog && this.dialog.showVideo
      return `${mediaKey}_${this.uploadType}_${this.showDialogDefaultMedia}_${showVideo}`
    },
    mediaImg() {
      if (this.dialog) {
        if (this.showDialogDefaultMedia) {
          return this.dialog.showVideo ? this.embedVideoURL : this.imageURL
        }
        if (this.uploadType) {
          if (this.uploadSubtype) {
            return this.dialog[this.uploadType][this.uploadSubtype]
          } else {
            return this.dialog[this.uploadType] ? this.dialog[this.uploadType] : this.imageURL
          }
        }
        return this.imageURL
      }
      return this.dialog && this.dialog.showVideo ? this.embedVideoURL : this.imageURL
    },
    dialogSloganTitle() {
      if (this.selectedLanguageForWebConnect && this.selectedLanguageForWebConnect.code !== 'en' && this.selectedWidgetTranslation) {
        return this.selectedWidgetTranslation.dialogTitleDefault
      }
      return this.dialog && this.hasWhiteLabel ? this.dialog.sloganTitle : this.defaultSloganTitle
    },
    step2ButtonColor() {
      return {
        backgroundColor: this.dialog.step2ButtonColor,
        boxShadow: '0 2px 6px 0 rgb(0 0 0 / 40%)'
      }
    },
    step2IconColor() {
      return this.dialog && this.dialog.step2IconColor ? this.dialog.step2IconColor : '#fff'
    },
    step2TextColor() {
      return {
        color: this.dialog.step2TextColor
      }
    }
  }
}
</script>
