var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "button-plus-text-new",
      style: _vm.buttonPlusTextNewPreviewStyle,
    },
    [
      _c("div", { staticClass: "button-plus-text-new__bubble" }, [
        _c(
          "div",
          { staticClass: "button-plus-text-new__bubble__close" },
          [
            _c("close-icon", {
              attrs: { width: 13, height: 13, color: "#53535B" },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "flex items-start" },
          [
            _c("button-plus-text-new-container", {
              attrs: {
                isForPreviewScreen: _vm.isForPreviewScreen,
                uploadType: _vm.uploadType,
                uploadSubtype: _vm.uploadSubtype,
                dialog: _vm.dialog,
                imageURL: _vm.imageURL,
                embedVideoURL: _vm.embedVideoURL,
              },
            }),
            _c("div", { staticClass: "button-plus-text-new__bubble__text" }, [
              _c("span", { staticStyle: { "white-space": "pre-line" } }, [
                _vm._v(_vm._s(_vm.dialogText)),
              ]),
            ]),
          ],
          1
        ),
        _c("div", { staticClass: "button-plus-text-new__bubble__arrow" }),
      ]),
      _c(
        "div",
        {
          staticClass: "button-plus-text-new__icon",
          style: _vm.buttonPlusTextNewMediaIconColor,
        },
        [
          _c("img", {
            attrs: {
              src: require("@/assets/images/dialog/messages-bubble.svg"),
              alt: "messages-bubble",
            },
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }