<template>
  <div
    class="button-preview"
    :class="{
      'button-preview_small': previewType,
      'button-preview_previewScreen': isForPreviewScreen,
      'button-preview_previewScreenSmall': isForPreviewScreen && dialog && dialog.isAlwaysShowButtonEnabled,
      'button-preview_previewScreenSmallest': isForPreviewScreen && dialog && dialog.isSmallButtonPreviewVisible
    }"
    :style="buttonPreviewStyle"
  >
    <div
      class="flex flex-col"
      :class="{
        'items-end': dialog.type === 'button' || isForPreviewScreen
      }"
    >
      <div class="button-preview__speech-bubble" v-if="dialog.type === 'button'">
        <div class="button-preview__speech-bubble-container" role="dialog" aria-label="You have received a message">
          <button class="button-preview__speech-bubble-close" title="Dismiss" aria-label="Dismiss">
            <a href="#" class="button-preview__speech-bubble-close-icon"></a>
          </button>
          <div class="button-preview__speech-bubble-item">
            <span class="button-preview__speech-bubble-arrow"></span>
            <div class="button-preview__speech-bubble-text-container">
              <div class="button-preview__speech-bubble-text">
                <span style="white-space: pre-line">{{ dialogText }}</span>
              </div>
              <div class="flex items-center justify-between">
                <div class="button-preview__speech-bubble-powered-by" v-if="!hasWhiteLabel">
                  <img src="/favicon.ico" />
                  <span class="button-preview__speech-bubble-powered-by-text">powered by</span>
                  <span class="button-preview__speech-bubble-powered-by-company">Let's Connect</span>
                </div>
                <button class="button-preview__button-confirm" :style="buttonColor">
                  <p>{{ dialog.confirmButtonText }}</p>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <button-preview-container
        :ownMediaKey="ownMediaKey"
        :isForPreviewScreen="isForPreviewScreen"
        :uploadType="uploadType"
        :uploadSubtype="uploadSubtype"
        :dialog="dialog"
        :canShowMinimizeIcon="canShowMinimizeIcon"
        :imageURL="imageURL"
        :embedVideoURL="embedVideoURL"
        :smallButtonImage="smallButtonImage"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import ButtonPreviewContainer from '@/components/dialog/media/ButtonPreviewContainer'

export default {
  name: 'ButtonPreview',
  components: {
    ButtonPreviewContainer
  },
  props: {
    ownMediaKey: {
      type: String
    },
    isDisplayOptions: {
      type: Boolean,
      default: false
    },
    canShowMinimizeIcon: {
      type: Boolean,
      default: true
    },
    isForPreviewScreen: {
      type: Boolean,
      default: false
    },
    previewType: {
      type: String,
      default: ''
    },
    uploadType: {
      type: String
    },
    uploadSubtype: {
      type: String
    },
    dialog: {
      type: Object,
      required: true
    },
    imageURL: {
      type: String,
      required: false,
      default: null
    },
    embedVideoURL: {
      type: String,
      required: true
    },
    smallButtonImage: {
      type: String,
      required: false,
      default: null
    }
  },
  computed: {
    ...mapGetters({
      hasWhiteLabel: 'hasWhiteLabel',
      selectedLanguageForWebConnect: 'widgetTranslation/selectedLanguageForWebConnect',
      selectedWidgetTranslation: 'widgetTranslation/selectedWidgetTranslation'
    }),
    dialogText() {
      if (this.selectedLanguageForWebConnect && this.selectedLanguageForWebConnect.code === 'en') {
        if (this.dialog) return this.dialog.text
      }

      if (this.selectedLanguageForWebConnect && this.selectedLanguageForWebConnect.code !== 'en' && this.selectedWidgetTranslation) {
        return this.selectedWidgetTranslation.popupTextDefault
      }

      return ''
    },
    buttonPreviewStyle() {
      if (this.isDisplayOptions) {
        let position = 'right'
        if (this.dialog && this.dialog.position) {
          position = this.dialog.position
        }

        const style = { position: 'absolute', bottom: '10px', width: 'fit-content', minWidth: '0px' }
        if (position === 'right') {
          style.right = '10px'
        } else {
          style.left = '10px'
        }

        return style
      }
      return null
    },
    buttonColor() {
      return {
        backgroundColor: this.dialog.step2ButtonColor,
        color: this.dialog.step2IconColor
      }
    }
  }
}
</script>

<style lang="scss">
.button-preview {
  width: 278px;
  min-width: 278px;
  &.button-preview_small {
    .button-preview {
      &__online-container {
        left: 8px;
        top: 8px;
        &-online {
          width: 7px;
          height: 7px;
        }
        &-text {
          display: none;
        }
      }
      &__container,
      &__img,
      &__video {
        width: 80px;
        height: 80px;
      }
      &__img-container,
      &__video-container {
        height: 80px;
      }
      &__powered-by-container {
        &-header {
          font-size: 11px;
          font-weight: 500;
          &._small {
            font-size: 11px;
            font-weight: 500;
            white-space: nowrap;
          }
        }
        &-company {
          font-size: 6px;
        }
      }
    }
  }
  &.button-preview_previewScreen {
    .button-preview {
      &__minimize-container {
        width: 14px;
        height: 14px;
      }
      &__online-container {
        left: 8px;
        top: 8px;
        &-online {
          width: 7px;
          height: 7px;
        }
        &-text {
          font-size: 8px;
        }
      }
      &__container,
      &__img,
      &__video {
        width: 112.03px;
        height: 71.97px;
      }
      &__img-container,
      &__video-container {
        height: 71.97px;
      }
      &__powered-by-container {
        &-header {
          font-size: 12px;
          &._small {
            font-size: 11px;
            font-weight: 500;
            white-space: nowrap;
          }
        }
        &-company {
          font-size: 5px;
        }
      }
    }
  }
  &.button-preview_previewScreenSmall {
    .button-preview {
      &__online-container {
        left: 8px;
        top: 8px;
        &-online {
          width: 5px;
          height: 5px;
        }
        &-text {
          display: none;
        }
      }
      &__container,
      &__img,
      &__video {
        width: 71.97px;
        height: 71.97px;
      }
      &__img-container,
      &__video-container {
        height: 71.97px;
      }
    }
  }
  &.button-preview_previewScreenSmallest {
    .button-preview {
      &__online-container {
        display: none;
      }
      &__container,
      &__img,
      &__video {
        width: 40px;
        height: 40px;
      }
      &__smallest-img {
        height: 40px;
        width: 40px;
        min-width: 40px;
        position: absolute;
        right: 0;
        bottom: 0;
        background: #fff;
        border-radius: 6px;
        overflow: hidden;
        img {
          width: 40px;
          height: 40px;
        }
      }
      &__img-container,
      &__video-container,
      &__smallest-img-container {
        width: 40px;
        height: 40px;
      }
      &__smallest-img-container {
        position: relative;
        border-radius: 13px;
        overflow: hidden;
      }
      &__powered-by-container {
        display: none;
      }
    }
  }
  &__container {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 200px;
    height: 128px;
    border-radius: 13px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 6px 0px;
  }
  &__online-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 999;
    font-weight: 500;
    font-size: 12px;
    color: #ffffff;
    position: absolute;
    top: 10px;
    left: 10px;
    &-online {
      width: 10px;
      height: 10px;
      background-color: #2bff6f;
      border-radius: 50%;
    }
  }
  &__minimize-container {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
    width: 19px;
    height: 19px;
    background-color: rgba(var(--vs-secondary), 1);
    position: absolute;
    top: -8px;
    right: -3px;
    border-radius: 50%;
  }
  &__powered-by-container {
    z-index: 2;
    text-align: center;
    color: #fff;
    position: absolute;
    display: flex;
    bottom: 8px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    &-header {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 1;
      &._small {
        font-size: 11px;
        font-weight: 500;
        white-space: nowrap;
      }
    }
    &-company {
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
    }
  }
  &__img-container {
    position: relative;
    height: 128px;
    border-radius: 13px;
    overflow: hidden;
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
      border-radius: 6px;
    }
  }
  &__img {
    object-fit: cover;
    width: 200px;
    height: 128px;
    border-radius: 6px;
  }
  &__video-container {
    position: relative;
    height: 128px;
    border-radius: 13px;
    overflow: hidden;
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
      border-radius: 6px;
      z-index: 1;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  &__video {
    border-radius: 6px;
    object-fit: cover;
    width: 200px;
    height: 128px;
    position: relative;
    top: 0;
    left: 0;
  }

  &__speech-bubble {
    display: flex;
    max-width: 278px;
    min-width: 162px;
    margin-bottom: 31px;
    justify-content: flex-end;
  }
  &__speech-bubble-container {
    display: flex;
    position: relative;
  }
  &__speech-bubble-item {
    display: flex;
    position: relative;
    background-color: #fff;
    border-radius: 0.625rem;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.4);
    -ms-overflow-style: -ms-;
    min-width: 165px;
  }
  &__speech-bubble-text-container {
    overflow: hidden;
    position: relative;
    border-radius: inherit;
    cursor: pointer;
    min-width: 165px;
  }
  &__speech-bubble-text {
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 1rem;
    font-size: 1rem;
    white-space: normal;
    span {
      text-align: justify;
      text-justify: inter-word;
      display: inline-block;
    }
  }
  &__speech-bubble-arrow {
    display: block;
    position: absolute;
    content: '';
    width: 10px;
    height: 10px;
    right: 5px;
    bottom: -5px;
    background-color: inherit;
    transform: translateX(-6px) rotate(45deg);
    pointer-events: none;
    box-shadow: 2px -2px 2px 0 rgba(0, 0, 0, 0.2);
    transform: translateX(-6px) rotate(135deg);
  }
  &__speech-bubble-close {
    z-index: 2;
    width: 22px;
    height: 22px;
    color: #777;
    font: 10px/100% arial, sans-serif;
    text-decoration: none;
    text-shadow: 0 1px 0 #fff;
    top: -10px;
    border: 0px;
    position: absolute;
    left: -10px;
    border: 1px solid black;
    box-shadow: 0px 0px 5px 1px grey;
    border-color: gray;
    border-radius: 15px;
    opacity: 0.9;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    &-icon {
      display: flex;
      align-self: center;
      justify-content: center;
      color: gray;
      text-decoration: none;
      &:after {
        content: '✖';
      }
    }
  }
  &__button-confirm {
    border-radius: 5px;
    margin: 5px;
    float: right;
    margin-left: auto;
    cursor: pointer;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.4);
    border: none;
    font-size: 1rem;
    p {
      padding: 7px 20px;
      margin: 0;
    }
  }
  &__speech-bubble-powered-by {
    display: flex;
    align-items: center;
    padding-left: 1rem;
    img {
      width: 12px;
      height: 12px;
      min-width: 12px;
      margin-right: 3px;
    }
    &-text,
    &-company {
      font-size: 8px;
      color: grey;
    }
    &-company {
      color: #3b86f7;
      font-weight: bold;
      margin-left: 3px;
      text-transform: uppercases;
    }
  }
}
</style>
