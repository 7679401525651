var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "button-preview",
      class: {
        "button-preview_small": _vm.previewType,
        "button-preview_previewScreen": _vm.isForPreviewScreen,
        "button-preview_previewScreenSmall":
          _vm.isForPreviewScreen &&
          _vm.dialog &&
          _vm.dialog.isAlwaysShowButtonEnabled,
        "button-preview_previewScreenSmallest":
          _vm.isForPreviewScreen &&
          _vm.dialog &&
          _vm.dialog.isSmallButtonPreviewVisible,
      },
      style: _vm.buttonPreviewStyle,
    },
    [
      _c(
        "div",
        {
          staticClass: "flex flex-col",
          class: {
            "items-end": _vm.dialog.type === "button" || _vm.isForPreviewScreen,
          },
        },
        [
          _vm.dialog.type === "button"
            ? _c("div", { staticClass: "button-preview__speech-bubble" }, [
                _c(
                  "div",
                  {
                    staticClass: "button-preview__speech-bubble-container",
                    attrs: {
                      role: "dialog",
                      "aria-label": "You have received a message",
                    },
                  },
                  [
                    _vm._m(0),
                    _c(
                      "div",
                      { staticClass: "button-preview__speech-bubble-item" },
                      [
                        _c("span", {
                          staticClass: "button-preview__speech-bubble-arrow",
                        }),
                        _c(
                          "div",
                          {
                            staticClass:
                              "button-preview__speech-bubble-text-container",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "button-preview__speech-bubble-text",
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticStyle: { "white-space": "pre-line" },
                                  },
                                  [_vm._v(_vm._s(_vm.dialogText))]
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "flex items-center justify-between",
                              },
                              [
                                !_vm.hasWhiteLabel
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "button-preview__speech-bubble-powered-by",
                                      },
                                      [
                                        _c("img", {
                                          attrs: { src: "/favicon.ico" },
                                        }),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "button-preview__speech-bubble-powered-by-text",
                                          },
                                          [_vm._v("powered by")]
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "button-preview__speech-bubble-powered-by-company",
                                          },
                                          [_vm._v("Let's Connect")]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "button-preview__button-confirm",
                                    style: _vm.buttonColor,
                                  },
                                  [
                                    _c("p", [
                                      _vm._v(
                                        _vm._s(_vm.dialog.confirmButtonText)
                                      ),
                                    ]),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
              ])
            : _vm._e(),
          _c("button-preview-container", {
            attrs: {
              ownMediaKey: _vm.ownMediaKey,
              isForPreviewScreen: _vm.isForPreviewScreen,
              uploadType: _vm.uploadType,
              uploadSubtype: _vm.uploadSubtype,
              dialog: _vm.dialog,
              canShowMinimizeIcon: _vm.canShowMinimizeIcon,
              imageURL: _vm.imageURL,
              embedVideoURL: _vm.embedVideoURL,
              smallButtonImage: _vm.smallButtonImage,
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "button-preview__speech-bubble-close",
        attrs: { title: "Dismiss", "aria-label": "Dismiss" },
      },
      [
        _c("a", {
          staticClass: "button-preview__speech-bubble-close-icon",
          attrs: { href: "#" },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }