var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "button-preview__container" },
    [
      _vm._m(0),
      _vm.canShowMinimizeIcon
        ? _c(
            "div",
            {
              staticClass:
                "button-preview__minimize-container flex justify-center items-center",
              style: _vm.step2ButtonColor,
            },
            [
              _c("close-minimize-icon", {
                attrs: {
                  width: 9,
                  height: 9,
                  color: _vm.dialog.step2ButtonColor,
                  stroke: _vm.step2IconColor,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "button-preview__powered-by-container" }, [
        _c(
          "div",
          {
            staticClass: "button-preview__powered-by-container-header",
            class: {
              _small:
                _vm.previewType === "small" ||
                (_vm.isForPreviewScreen &&
                  _vm.dialog.isAlwaysShowButtonEnabled),
            },
          },
          [_vm._v("\n      " + _vm._s(_vm.dialogSloganTitle) + "\n    ")]
        ),
      ]),
      !_vm.isForPreviewScreen
        ? [
            _vm.showDialogDefaultMedia
              ? [
                  _vm.dialog && !_vm.dialog.showVideo
                    ? _c(
                        "div",
                        { staticClass: "button-preview__img-container" },
                        [
                          _c("img", {
                            key: _vm.mediaKey,
                            staticClass: "button-preview__img",
                            attrs: { src: _vm.imageURL },
                          }),
                        ]
                      )
                    : _c(
                        "div",
                        { staticClass: "button-preview__video-container" },
                        [
                          _vm.mediaImg
                            ? _c(
                                "video",
                                {
                                  key: _vm.mediaKey,
                                  staticClass: "button-preview__video",
                                  attrs: {
                                    playsinline: "",
                                    autoplay: "",
                                    muted: "",
                                    loop: "",
                                  },
                                  domProps: { muted: true },
                                },
                                [
                                  _vm.mediaImg.indexOf(".webm") > -1
                                    ? _c("source", {
                                        attrs: {
                                          src: _vm.mediaImg,
                                          type: _vm.webm,
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.mediaImg.indexOf(".mp4") > -1
                                    ? _c("source", {
                                        attrs: {
                                          src: _vm.mediaImg,
                                          type: _vm.mp4,
                                        },
                                      })
                                    : _vm._e(),
                                ]
                              )
                            : _vm._e(),
                        ]
                      ),
                ]
              : [
                  _vm.mediaImg
                    ? [
                        _vm.mediaImg.indexOf(".webm") > -1 ||
                        _vm.mediaImg.indexOf(".mp4") > -1
                          ? _c(
                              "div",
                              {
                                staticClass: "button-preview__video-container",
                              },
                              [
                                _c(
                                  "video",
                                  {
                                    key: _vm.mediaKey,
                                    staticClass: "button-preview__video",
                                    attrs: {
                                      playsinline: "",
                                      autoplay: "",
                                      muted: "",
                                      loop: "",
                                    },
                                    domProps: { muted: true },
                                  },
                                  [
                                    _vm.mediaImg.indexOf(".webm") > -1
                                      ? _c("source", {
                                          attrs: {
                                            src: _vm.mediaImg,
                                            type: _vm.webm,
                                          },
                                        })
                                      : _vm._e(),
                                    _vm.mediaImg.indexOf(".mp4") > -1
                                      ? _c("source", {
                                          attrs: {
                                            src: _vm.mediaImg,
                                            type: _vm.mp4,
                                          },
                                        })
                                      : _vm._e(),
                                  ]
                                ),
                              ]
                            )
                          : _c(
                              "div",
                              { staticClass: "button-preview__img-container" },
                              [
                                _c("img", {
                                  key: _vm.mediaKey,
                                  staticClass: "button-preview__img",
                                  attrs: { src: _vm.mediaImg },
                                }),
                              ]
                            ),
                      ]
                    : _vm._e(),
                ],
          ]
        : _vm._e(),
      _vm.isForPreviewScreen
        ? [
            _vm.dialog && !_vm.dialog.isSmallButtonPreviewVisible
              ? [
                  _vm.showDialogDefaultMedia
                    ? [
                        _vm.dialog && !_vm.dialog.showVideo
                          ? _c(
                              "div",
                              { staticClass: "button-preview__img-container" },
                              [
                                _c("img", {
                                  staticClass: "button-preview__img",
                                  attrs: { src: _vm.imageURL },
                                }),
                              ]
                            )
                          : _c(
                              "div",
                              {
                                staticClass: "button-preview__video-container",
                              },
                              [
                                _vm.mediaImg
                                  ? _c(
                                      "video",
                                      {
                                        key: _vm.mediaKey,
                                        staticClass: "button-preview__video",
                                        attrs: {
                                          playsinline: "",
                                          autoplay: "",
                                          muted: "",
                                          loop: "",
                                        },
                                        domProps: { muted: true },
                                      },
                                      [
                                        _vm.mediaImg.indexOf(".webm") > -1
                                          ? _c("source", {
                                              attrs: {
                                                src: _vm.mediaImg,
                                                type: _vm.webm,
                                              },
                                            })
                                          : _vm._e(),
                                        _vm.mediaImg.indexOf(".mp4") > -1
                                          ? _c("source", {
                                              attrs: {
                                                src: _vm.mediaImg,
                                                type: _vm.mp4,
                                              },
                                            })
                                          : _vm._e(),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            ),
                      ]
                    : [
                        _vm.mediaImg
                          ? [
                              _vm.mediaImg.indexOf(".webm") > -1 ||
                              _vm.mediaImg.indexOf(".mp4") > -1
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "button-preview__video-container",
                                    },
                                    [
                                      _c(
                                        "video",
                                        {
                                          key: _vm.mediaKey,
                                          staticClass: "button-preview__video",
                                          attrs: {
                                            playsinline: "",
                                            autoplay: "",
                                            muted: "",
                                            loop: "",
                                          },
                                          domProps: { muted: true },
                                        },
                                        [
                                          _vm.mediaImg.indexOf(".webm") > -1
                                            ? _c("source", {
                                                attrs: {
                                                  src: _vm.mediaImg,
                                                  type: _vm.webm,
                                                },
                                              })
                                            : _vm._e(),
                                          _vm.mediaImg.indexOf(".mp4") > -1
                                            ? _c("source", {
                                                attrs: {
                                                  src: _vm.mediaImg,
                                                  type: _vm.mp4,
                                                },
                                              })
                                            : _vm._e(),
                                        ]
                                      ),
                                    ]
                                  )
                                : _c(
                                    "div",
                                    {
                                      staticClass:
                                        "button-preview__img-container",
                                    },
                                    [
                                      _c("img", {
                                        key: _vm.mediaKey,
                                        staticClass: "button-preview__img",
                                        attrs: { src: _vm.mediaImg },
                                      }),
                                    ]
                                  ),
                            ]
                          : _vm._e(),
                      ],
                ]
              : [
                  _c(
                    "div",
                    { staticClass: "button-preview__smallest-img-container" },
                    [
                      _c(
                        "div",
                        { staticClass: "button-preview__smallest-img" },
                        [
                          _c("img", {
                            key: _vm.mediaKey,
                            attrs: { src: _vm.smallButtonImage },
                          }),
                        ]
                      ),
                    ]
                  ),
                ],
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "button-preview__online-container" }, [
      _c("div", { staticClass: "button-preview__online-container-online" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }