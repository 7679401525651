<template>
  <div class="button-preview-new__container">
    <div class="button-preview-new__online-container">
      <div class="button-preview-new__online-container-online"></div>
    </div>
    <template v-if="!isForPreviewScreen">
      <template v-if="showDialogDefaultMedia">
        <div v-if="dialog && !dialog.showVideo" class="button-preview-new__img-container">
          <img class="button-preview-new__img" :src="imageURL" />
        </div>
        <div v-else class="button-preview-new__video-container">
          <video class="button-preview-new__video" v-if="embedVideoURL" playsinline autoplay muted loop>
            <source v-if="embedVideoURL.indexOf('.webm') > -1" :src="embedVideoURL" :type="webm" />
            <source v-if="embedVideoURL.indexOf('.mp4') > -1" :src="embedVideoURL" :type="mp4" />
          </video>
        </div>
      </template>
      <template v-else>
        <template v-if="mediaImg">
          <div v-if="mediaImg.indexOf('.webm') > -1 || mediaImg.indexOf('.mp4') > -1" class="button-preview-new__video-container">
            <video class="button-preview-new__video" playsinline autoplay muted loop>
              <source v-if="mediaImg.indexOf('.webm') > -1" :src="mediaImg" :type="webm" />
              <source v-if="mediaImg.indexOf('.mp4') > -1" :src="mediaImg" :type="mp4" />
            </video>
          </div>
          <div v-else class="button-preview-new__img-container">
            <img class="button-preview-new__img" :src="mediaImg" />
          </div>
        </template>
      </template>
    </template>
    <template v-if="isForPreviewScreen">
      <template>
        <template v-if="showDialogDefaultMedia">
          <div v-if="dialog && !dialog.showVideo" class="button-preview-new__img-container">
            <img class="button-preview-new__img" :src="imageURL" />
          </div>
          <div v-else class="button-preview-new__video-container">
            <video class="button-preview-new__video" v-if="embedVideoURL" playsinline autoplay muted loop>
              <source v-if="embedVideoURL.indexOf('.webm') > -1" :src="embedVideoURL" :type="webm" />
              <source v-if="embedVideoURL.indexOf('.mp4') > -1" :src="embedVideoURL" :type="mp4" />
            </video>
          </div>
        </template>
        <template v-else>
          <template v-if="mediaImg">
            <div v-if="mediaImg.indexOf('.webm') > -1 || mediaImg.indexOf('.mp4') > -1" class="button-preview-new__video-container">
              <video class="button-preview-new__video" playsinline autoplay muted loop>
                <source v-if="mediaImg.indexOf('.webm') > -1" :src="mediaImg" :type="webm" />
                <source v-if="mediaImg.indexOf('.mp4') > -1" :src="mediaImg" :type="mp4" />
              </video>
            </div>
            <div v-else class="button-preview-new__img-container">
              <img class="button-preview-new__img" :src="mediaImg" />
            </div>
          </template>
        </template>
      </template>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    isForPreviewScreen: {
      type: Boolean,
      default: false
    },
    previewType: {
      type: String,
      default: ''
    },
    uploadType: {
      type: String
    },
    uploadSubtype: {
      type: String
    },
    dialog: {
      type: Object,
      required: true
    },
    imageURL: {
      type: String,
      required: false,
      default: null
    },
    embedVideoURL: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      webm: 'video/webm',
      mp4: 'video/mp4'
    }
  },
  computed: {
    ...mapGetters({
      hasWhiteLabel: 'hasWhiteLabel'
    }),
    showDialogDefaultMedia() {
      if (this.dialog && this.dialog.showDefaultMedia) {
        return true
      }
      return false
    },
    mediaImg() {
      if (this.dialog) {
        if (this.uploadType) {
          if (this.uploadSubtype) {
            return this.dialog[this.uploadType][this.uploadSubtype]
          } else {
            return this.dialog[this.uploadType] ? this.dialog[this.uploadType] : this.imageURL
          }
        }
        return this.imageURL
      }
      return this.dialog && this.dialog.showVideo ? this.embedVideoURL : this.imageURL
    }
  }
}
</script>

<style lang="scss">
.button-preview-new {
  width: 278px;
  min-width: 278px;
  &__container {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 60px;
    min-width: 60px;
    height: 60px;
    border-radius: 12px;
  }
  &__online-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 999;
    font-weight: 500;
    font-size: 12px;
    color: #ffffff;
    position: absolute;
    top: -5px;
    right: -5px;
    &-online {
      width: 17px;
      height: 17px;
      background: #2bff6f;
      border-radius: 50%;
    }
  }
  &__img-container {
    position: relative;
    height: 60px;
    border-radius: 12px;
    overflow: hidden;
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 6px;
    }
  }
  &__img {
    object-fit: cover;
    width: 60px;
    min-width: 60px;
    height: 60px;
    border-radius: 6px;
  }
  &__video-container {
    position: relative;
    height: 60px;
    border-radius: 12px;
    overflow: hidden;
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 6px;
      z-index: 1;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  &__video {
    border-radius: 6px;
    object-fit: cover;
    width: 60px;
    min-width: 60px;
    height: 60px;
    position: relative;
    top: 0;
    left: 0;
  }
}
</style>
