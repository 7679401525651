var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "button-preview-new__container" },
    [
      _vm._m(0),
      !_vm.isForPreviewScreen
        ? [
            _vm.showDialogDefaultMedia
              ? [
                  _vm.dialog && !_vm.dialog.showVideo
                    ? _c(
                        "div",
                        { staticClass: "button-preview-new__img-container" },
                        [
                          _c("img", {
                            staticClass: "button-preview-new__img",
                            attrs: { src: _vm.imageURL },
                          }),
                        ]
                      )
                    : _c(
                        "div",
                        { staticClass: "button-preview-new__video-container" },
                        [
                          _vm.embedVideoURL
                            ? _c(
                                "video",
                                {
                                  staticClass: "button-preview-new__video",
                                  attrs: {
                                    playsinline: "",
                                    autoplay: "",
                                    muted: "",
                                    loop: "",
                                  },
                                  domProps: { muted: true },
                                },
                                [
                                  _vm.embedVideoURL.indexOf(".webm") > -1
                                    ? _c("source", {
                                        attrs: {
                                          src: _vm.embedVideoURL,
                                          type: _vm.webm,
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.embedVideoURL.indexOf(".mp4") > -1
                                    ? _c("source", {
                                        attrs: {
                                          src: _vm.embedVideoURL,
                                          type: _vm.mp4,
                                        },
                                      })
                                    : _vm._e(),
                                ]
                              )
                            : _vm._e(),
                        ]
                      ),
                ]
              : [
                  _vm.mediaImg
                    ? [
                        _vm.mediaImg.indexOf(".webm") > -1 ||
                        _vm.mediaImg.indexOf(".mp4") > -1
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "button-preview-new__video-container",
                              },
                              [
                                _c(
                                  "video",
                                  {
                                    staticClass: "button-preview-new__video",
                                    attrs: {
                                      playsinline: "",
                                      autoplay: "",
                                      muted: "",
                                      loop: "",
                                    },
                                    domProps: { muted: true },
                                  },
                                  [
                                    _vm.mediaImg.indexOf(".webm") > -1
                                      ? _c("source", {
                                          attrs: {
                                            src: _vm.mediaImg,
                                            type: _vm.webm,
                                          },
                                        })
                                      : _vm._e(),
                                    _vm.mediaImg.indexOf(".mp4") > -1
                                      ? _c("source", {
                                          attrs: {
                                            src: _vm.mediaImg,
                                            type: _vm.mp4,
                                          },
                                        })
                                      : _vm._e(),
                                  ]
                                ),
                              ]
                            )
                          : _c(
                              "div",
                              {
                                staticClass:
                                  "button-preview-new__img-container",
                              },
                              [
                                _c("img", {
                                  staticClass: "button-preview-new__img",
                                  attrs: { src: _vm.mediaImg },
                                }),
                              ]
                            ),
                      ]
                    : _vm._e(),
                ],
          ]
        : _vm._e(),
      _vm.isForPreviewScreen
        ? [
            [
              _vm.showDialogDefaultMedia
                ? [
                    _vm.dialog && !_vm.dialog.showVideo
                      ? _c(
                          "div",
                          { staticClass: "button-preview-new__img-container" },
                          [
                            _c("img", {
                              staticClass: "button-preview-new__img",
                              attrs: { src: _vm.imageURL },
                            }),
                          ]
                        )
                      : _c(
                          "div",
                          {
                            staticClass: "button-preview-new__video-container",
                          },
                          [
                            _vm.embedVideoURL
                              ? _c(
                                  "video",
                                  {
                                    staticClass: "button-preview-new__video",
                                    attrs: {
                                      playsinline: "",
                                      autoplay: "",
                                      muted: "",
                                      loop: "",
                                    },
                                    domProps: { muted: true },
                                  },
                                  [
                                    _vm.embedVideoURL.indexOf(".webm") > -1
                                      ? _c("source", {
                                          attrs: {
                                            src: _vm.embedVideoURL,
                                            type: _vm.webm,
                                          },
                                        })
                                      : _vm._e(),
                                    _vm.embedVideoURL.indexOf(".mp4") > -1
                                      ? _c("source", {
                                          attrs: {
                                            src: _vm.embedVideoURL,
                                            type: _vm.mp4,
                                          },
                                        })
                                      : _vm._e(),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        ),
                  ]
                : [
                    _vm.mediaImg
                      ? [
                          _vm.mediaImg.indexOf(".webm") > -1 ||
                          _vm.mediaImg.indexOf(".mp4") > -1
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "button-preview-new__video-container",
                                },
                                [
                                  _c(
                                    "video",
                                    {
                                      staticClass: "button-preview-new__video",
                                      attrs: {
                                        playsinline: "",
                                        autoplay: "",
                                        muted: "",
                                        loop: "",
                                      },
                                      domProps: { muted: true },
                                    },
                                    [
                                      _vm.mediaImg.indexOf(".webm") > -1
                                        ? _c("source", {
                                            attrs: {
                                              src: _vm.mediaImg,
                                              type: _vm.webm,
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.mediaImg.indexOf(".mp4") > -1
                                        ? _c("source", {
                                            attrs: {
                                              src: _vm.mediaImg,
                                              type: _vm.mp4,
                                            },
                                          })
                                        : _vm._e(),
                                    ]
                                  ),
                                ]
                              )
                            : _c(
                                "div",
                                {
                                  staticClass:
                                    "button-preview-new__img-container",
                                },
                                [
                                  _c("img", {
                                    staticClass: "button-preview-new__img",
                                    attrs: { src: _vm.mediaImg },
                                  }),
                                ]
                              ),
                        ]
                      : _vm._e(),
                  ],
            ],
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "button-preview-new__online-container" }, [
      _c("div", { staticClass: "button-preview-new__online-container-online" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }