<template>
  <div class="button-plus-text-new" :style="buttonPlusTextNewPreviewStyle">
    <div class="button-plus-text-new__bubble">
      <div class="button-plus-text-new__bubble__close">
        <close-icon :width="13" :height="13" color="#53535B" />
      </div>
      <div class="flex items-start">
        <button-plus-text-new-container
          :isForPreviewScreen="isForPreviewScreen"
          :uploadType="uploadType"
          :uploadSubtype="uploadSubtype"
          :dialog="dialog"
          :imageURL="imageURL"
          :embedVideoURL="embedVideoURL"
        />
        <div class="button-plus-text-new__bubble__text">
          <span style="white-space: pre-line">{{ dialogText }}</span>
        </div>
      </div>
      <div class="button-plus-text-new__bubble__arrow"></div>
    </div>
    <div class="button-plus-text-new__icon" :style="buttonPlusTextNewMediaIconColor">
      <img :src="require('@/assets/images/dialog/messages-bubble.svg')" alt="messages-bubble" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ButtonPlusTextNewContainer from '@/components/dialog/media/ButtonPlusTextNewContainer'
import CloseIcon from '@/components/icons/CloseIcon'
export default {
  components: {
    ButtonPlusTextNewContainer,
    CloseIcon
  },
  props: {
    isDisplayOptions: {
      type: Boolean,
      default: false
    },
    isForPreviewScreen: {
      type: Boolean,
      default: false
    },
    previewType: {
      type: String,
      default: ''
    },
    uploadType: {
      type: String
    },
    uploadSubtype: {
      type: String
    },
    dialog: {
      type: Object,
      required: true
    },
    imageURL: {
      type: String,
      required: false,
      default: null
    },
    embedVideoURL: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      selectedLanguageForWebConnect: 'widgetTranslation/selectedLanguageForWebConnect',
      selectedWidgetTranslation: 'widgetTranslation/selectedWidgetTranslation'
    }),
    dialogText() {
      if (this.selectedLanguageForWebConnect && this.selectedLanguageForWebConnect.code === 'en') {
        if (this.dialog) return this.dialog.text
      }

      if (this.selectedLanguageForWebConnect && this.selectedLanguageForWebConnect.code !== 'en' && this.selectedWidgetTranslation) {
        return this.selectedWidgetTranslation.popupTextDefault
      }

      return ''
    },
    buttonPlusTextNewMediaIconColor() {
      return {
        backgroundColor: this.dialog.buttonPlusTextNewMediaIconColor || '#3034F7'
      }
    },
    buttonPlusTextNewPreviewStyle() {
      if (this.isDisplayOptions) {
        let position = 'right'
        if (this.dialog && this.dialog.position) {
          position = this.dialog.position
        }

        const style = { position: 'absolute', bottom: '10px', width: 'fit-content', minWidth: '0px' }
        if (position === 'right') {
          style.right = '10px'
        } else {
          style.left = '10px'
        }

        return style
      }
      return null
    }
  }
}
</script>

<style lang="scss">
.button-plus-text-new {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;
  z-index: 1;
  &__bubble {
    width: 100%;
    max-width: 278px;
    min-width: 278px;
    min-height: 73px;
    background: #ffffff;
    box-shadow: 0px 7px 9px rgba(92, 93, 161, 0.1);
    border-radius: 20px;
    padding: 8.5px;
    box-sizing: border-box;
    position: relative;
    &__close {
      position: absolute;
      right: 10px;
      top: 6px;
    }
    &__arrow {
      width: 0;
      height: 0;
      line-height: 0;
      border-bottom: 20px solid transparent;
      border-left: 20px solid transparent;
      border-right: 20px solid #fff;
      border-bottom-right-radius: 15px;
      position: absolute;
      bottom: -20px;
      right: 35px;
    }
    &__text {
      min-height: 60px;
      display: flex;
      align-items: center;
      padding-right: 13px;
      margin-left: 15px;
      font-size: 14px;
      letter-spacing: 0.04375px;
      color: #53535b;
    }
  }
  &__icon {
    width: 60px;
    height: 60px;
    background: #3034f7;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 28px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 6px 0px;
  }
}
</style>
